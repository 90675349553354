<template>
	<nav class="nav">
		<div class="app__container nav__container">
			<router-link class="nav__site-name" :to='{name: "MainPage"}'>
				<webp-image name="logo" ext="png" alt="Akari Sushi" :lazyImg="false" />
			</router-link>

			<template v-if="!menuCardMode">
				<ul class="nav__menu" :class="{'is-mobile-menu-open': mobileMenuOpen}">
					<li>
						<router-link :to='{name: "HappyHours"}'>
							<span>Lunch</span>
						</router-link>
					</li>
					<template v-if='current_user'>
						<li>
							<router-link :to='{name: "Account"}'>
								<i class="fas fa-user"></i>
								<span>{{ $t('nav.profile') }}</span>
							</router-link>
						</li>
						<li v-if='!current_user.is_staff && !current_user.is_driver'>
							<button class="button is-link" @click="$modal.show('pointsInfoModal')">
								<span>Punkty: {{ current_user.points }}</span>
							</button>
						</li>
						<li v-if='!current_user.is_staff && !current_user.is_driver'>
							<router-link :to='{name: "Orders"}'>
								<span>{{ $t('nav.orders') }}</span>
							</router-link>
						</li>
						<li v-if='current_user.is_staff'>
							<router-link :to='{name: "StaffTables"}'>
								<span>Stoliki</span>
							</router-link>
						</li>
						<StaffOrdersButton v-if='current_user.is_staff || current_user.is_driver' />
						<li v-if='current_user.is_staff'>
							<router-link :to='{name: "Admin"}'>
								<i class="fas fa-cog"></i>
								<span>{{ $t('nav.admin') }}</span>
							</router-link>
						</li>
						<li>
							<button class="button is-link" @click='onLogout'>
								<i class="fas fa-sign-out-alt"></i>
								<span>{{ $t('nav.logout') }}</span>
							</button>
						</li>
					</template>
					<template v-else>
						<li>
							<router-link :to='{name: "Login"}'>
								<span>{{ $t('nav.login') }}</span>
							</router-link>
						</li>
					</template>
					<LocaleChanger />
					<!-- <LocaleChanger v-if="current_user && current_user.is_admin" /> -->
					<OrderButton />
					<PhoneSelect />
				</ul>

				<button class="nav__menu-toggle is-link" @click='toggleMobileMenu' aria-label="Menu">
					<i v-if="mobileMenuOpen" class="fas fa-times"></i>
					<i v-else class="fas fa-bars"></i>
				</button>
			</template>
		</div>
	</nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleChanger from '../others/LocaleChanger'
import StaffOrdersButton from '../admin/orders/StaffOrdersButton'
import OrderButton from '../others/OrderButton'
import PhoneSelect from '../others/PhoneSelect'
import { trans } from '../../i18n/functions'

export default {
	props: ['menuCardMode'],
	components: { LocaleChanger, StaffOrdersButton, PhoneSelect, OrderButton },
	computed: {
		...mapGetters({
			siteName: 'siteName',
            specialMenus: 'specialMenus',
			mobileMenuOpen: 'mobileMenuOpen',
			current_user: 'user/current_user',
		}),
	},
	methods: {
		trans,
		...mapActions({
			toggleMobileMenu: 'toggleMobileMenu',
			logout: 'user/logout',
		}),
		onLogout () {
			this.logout()
				.then(() => {
					this.$toastr.s(this.$t('messages.logout'))
				})
		}
	}
}
</script>

<style lang="scss">
@import '../../assets/css/breakpoints.scss';

.nav {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;

	height: var(--nav-height);

	> .app__container {
		overflow-x: visible;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__site-name {
		display: inline-block;
		height: var(--nav-height);
		padding: 15px;
		box-sizing: border-box;
		// background-color: var(--nav-background);
		background-color: var(--mobile-background);

		img {
			height: 100%;
		}
	}

	&__menu-toggle {
		display: none;

		@include bp-mobilenav {
			display: block;
			position: relative;
			z-index: 120;
			margin: 25px;
		}
	}

	&__menu {
		i + span {
			display: none;
		}

		@include bp-mobilenav {
			position: absolute;
			visibility: hidden;
			pointer-events: none;

			&.is-mobile-menu-open {
				visibility: visible;
				pointer-events: auto;
				z-index: 110;
				width: 100vw;
				min-height: 100vh;
				top: 0;
				left: 0;
				background-color: var(--mobile-background);
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: center;

				i {
					display: none;

					& + span {
						display: inline;
					}
				}

				.special-menu-button {
					margin-top: 10px;
				}
			}
		}

		> li {
			> a, > button.is-link {
				// color: var(--black);
				font-weight: bold;
				font-size: 16px;
				line-height: 46px;
				letter-spacing: 0.4px;
				text-transform: uppercase;
			}
			> .button {
				line-height: 30px;
			}
		}
	}
}
</style>