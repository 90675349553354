export default {
    name: 'Akari Sushi',
    loading: 'Loading',
    langchanger: 'Set page language',
    closed: {
        info1: 'The website is closed',
        info2: 'Please come back soon'
    },
    offline: {
        info1: 'Server is unavailable',
        info2: 'Please be patient, we are working on the problem'
    },
    nav: {
        profile: 'Your account',
        admin: 'Admin',
        logout: 'Log out',
        login: 'Log in',
        registration: 'Sign in',
        orders: 'Your orders',
        staffOrders: 'Orders',
    },
    cover: {
        info1: 'Sushi tailored for you! Traditional and fusion. Vegan sushi, gluten-free, fit! We will be happy to prepare them for you.',
        info2: 'We also invite you to try desserts and specialties.',
    },
    soupOfTheDay: {
        header: 'Dish of the day',
    },
    seeMenu: {
        header: 'Sushi is our passion',
        about: 'About the restaurant',
        team: 'Why Akari Sushi?',
        contact: 'Contact',
    },
    aboutUs: {
        restaurant: 'About the restaurant',
        virtual: 'Wirtual walk',
        whyUs: {
            header: "Why Akari Sushi?",
        },
    },
    contact: {
        header: 'Contact',
        social: 'Join us and follow seasonal promotions',
        welcome: 'We invite you',
        apartment: 'Apartment: {apartment}',
        floor: 'Floor: {floor}',
        staircase: 'Staircase: {staircase}',
        contact: 'Contact',
        akari: "Mokotów",
        theramen: "Śródmieście",
        hours: 'Opening hours',
        closed: 'Closed',
    },
    menu: {
        header: 'Menu',
        closed: 'Placing orders today is not available.<br/>We invite you back during the opening hours!',
        closedLong: 'Unfortunately, the restaurant is closed at the moment, we invite you in the next opening hours',
        adminClosed: 'Sorry, we currently have too many orders, please try again later',
        free_food: 'For each 100zł spent, we add the selected roll for free :)',
        free_addons: 'Free add-ons',
        disabledDate: 'The order for the selected date cannot be completed. In case of problems, please contact us by phone.',
        differentMenuError: 'You are trying to add dishes from two different menus. If you want to continue, your current basket will be cleared.',
        todaySpecial: 'Today there is a special menu: {title}',
        specialMenuInfo: 'Is valid on {date}',
        specialMenuActive: 'We have prepared a special menu for you! Check it out!',
        specialMenuPerOrder: 'There is a limit of orders for each hour, therefore some hours may not be available',
        specialMenuFull: 'Sorry. The order schedule is now full.',
        containPromoItems: 'The promotion applies to products ordered as of today. Normal price applies on other days.',
        mobile: {
            header: 'Select category',
            showDescription: 'Show dish description',
            showAllDishes: 'Show all dishes',
        },
    },
    cart: {
        addMore: 'Add to order',
        addMoreClose: 'Close',
        validationFields: {
            name: "Recipient's name",
            email: 'Email',
            tel: 'Phone number',
            payment_method: 'Payment method',
            order_type: 'How to pick up the order',
            address: 'Delivery address',
        },
        errors: {
            emptyFields: "Fill in all fields",
            deliveryNotPossible: "Delivery conditions were not met",
            dateNotSelected: "Please select delivery date",
            formData: "Missing fields: {missing}",
        },
    },
    order: {
        button: 'Order online',
        normalButton: 'Menu',
        phone: "Telephone",
        phone_akari: 'Mokotów: {phone}',
        phone_theramen: 'Śródmieście: {phone}',
        header: 'Your order',
        version: 'Dish version',
        addons: 'Addons',
        addToCart: 'Add to order',
        summary: 'Summary',
        sum: 'Sum',
        orderPrice: 'Order price',
        servicePrice: 'Service',
        discount: 'Discount',
        togo_discount: "Discount for personal pickup",
        deliveryPrice: 'Cost of delivery',
        totalPrice: 'Total amount',
        address: 'Delivery address',
        addAddress: 'Add address',
        editAddress: 'Edit address',
        addDishComment: 'Add dish comment',
        dishComment: 'Dish comment',
        delivery_unavailable: 'For this date delivery is unavailable. Pickup method changed to: Pickup in person',
        data: {
            header: 'Recipient Information',
            name: "Recipient's name",
            email: 'Email',
            tel: 'Phone number',
            peopleAmount: 'For how many people?',
            peopleAmountPlaceholder: 'For how many people? (optional)',
            nip: 'NIP',
            nipPlaceholder: 'NIP (optional)',
            comment: 'Comment on the order',
        },
        delivery_when: {
            header: 'Delivery',
            modalHeader: 'Select a delivery date and time',
            modalHeaderCallendar: 'Select a delivery day',
            modalHeaderTime: 'Select a delivery time',
            hourMainHeader: 'Delivery time',
            hourHoursHeader: 'Select hour',
            hourMinutesHeader: 'Select minute',
            changeDay: 'Change day',
            changeHour: 'Change hour',
            now: 'As soon as possible',
            select: 'Select date',
            selectSpecial: 'Select hour',
            specialMenuDay: 'On the selected day, the special {title} menu applies',
            specialMenuDayButton: 'Take me to the {title}',
            blocked: 'The order contains dishes from time categories that are only available in live orders',
        },
        payment_method: {
            header: 'Payment method',
            cash: 'Cash',
            card: 'Card',
            tpay: 'Online via tpay',
            pyszne_payment: 'Paid via pyszne.pl',
            uber_payment: 'Paid via Uber',
            glovo_payment: 'Paid via Glovo',
            wolt_payment: 'Paid via Wolt',
            only_points: 'Paid with points',
            select_later: 'Select later (table)',
        },
        order_type: {
            header: 'How to pick up the order',
            online: 'Delivery',
            to_go: 'Personal pickup',
            to_go_rabat: '10% discount',
            in_restaurant: 'I will eat in a restaurant',
        },
        create: 'Place an order',
        success: 'Order successfully placed',
        payButton: {
            tpay: 'Pay for your order online'
        },
        status: {
            created: 'Waiting for payment',
            queued: 'Waiting to be accepted by the restaurant',
            in_preparation: 'Order accepted. Your sushi is being prepared right now!',
            ended: 'The order is on its way to you or was picked up in person. Thank you!',
            canceled: 'Order canceled',
            active_table: 'Active table',
            delivery_when: 'The order will be processed on {date}',
        },
        shortStatus: {
            created: 'To pay',
            queued: 'In queue',
            in_preparation: 'Prepared',
            ended: 'Ended',
            canceled: 'Canceled',
            active_table: 'Table',
        },
        index: {
            header: 'Your orders'
        },
        estimated_delivery_time: 'Estimated delivery time: {time}',
        estimated_to_go_time: 'Order will be ready to pick up: {time}',
        promocode: {
            header: 'I have promocode',
            button: 'Use',
            success: 'A promotional code was used, it will be used when placing the order',
            errors: {
                null: 'Promo code does not exist',
                disabled: 'Promo code inactive',
                used: 'The promotional code has already been used',
                ended: 'The promotional code has expired',
                min_price: 'The minimum order price has not been reached',
                no_addon_table_end: 'No addons can be added when closing the table',
                no_amount_table_start: 'We add price discounts when closing the table',
            }
        },
        error: {
            too_far: 'Address outside the delivery area',
            min_price: 'The minimum order value at this distance is {min}zł',
            min_price_special: 'The minimum order value for this menu is {min}zł',
            free: 'For orders over {free}zł at this distance, delivery will be free',
        },
    },
    timepicker: {
        no_hours: 'There are no available hours for the selected day',
    },
    account: {
        header: 'My account',
        personalData: {
            header: 'Personal data',
            password: {
                header: 'Password change',
                change: 'Change password',
                success: 'Password changed successfully',
                info1: 'A strong password is needed to secure the account.',
                info2: 'You can change your password anytime.'
            },
            data: {
                success: 'Recipient information has been set successfully',
            },
            address: {
                success: 'Successfully set delivery address',
            },
            marketing: 'Marketing consents',
        },
    },
    invoice: {
        payment_method: {
            bank_transfer: 'Transfer',
            cash: 'Cash',
            online: 'Online payment',
            card: 'Payment by card',
        },
    },
    auth: {
        login: {
            header: 'Login',
            button: 'Log in'
        },
        registration: {
            header: 'Registration',
            button: 'Sign in',
        },
        forgotPassword: 'I forgot my password',
        sendReset: {
            header: 'Reset password',
            button: 'Send link to reset password'
        },
        reset: {
            header: 'Reset password',
            button: 'Reset password'
        },
        side: {
            register: {
                header: 'Welcome to {site}',
                info1: 'On {site} you can easily win big money.',
                info2: 'Fill out any coupon of the game.',
                info3: 'Fill the coupon sitting comfortably in the chair!'
            },
            sendReset: {
                header: 'Forgot your password?',
                info1: 'Enter the e-mail address on which your account is registered.',
                info2: 'A password reset link will be sent to the e-mail address provided.'
            },
            reset: {
                header: 'Reset password',
                info1: "You've started the password reseting process",
                info2: 'Enter a new password to be able to log in again on the site.'
            }
        }
    },
    terms: {
        header: 'Terms and conditions',
    },
    privacy_policy: {
        header: 'Privacy policy',
    },
    deliveryPrice: {
        header: 'Delivery prices',
    },
    noGluten: {
        header: 'Sushi without gluten',
    },
    catering: {
        header: 'Catering',
    },
    messages: {
        login: 'Login successful!',
        logout: 'Logout successful!',
        signup: 'Registration successful!',
        error: 'Error',
        mustLogin: 'You must log in',
        accessDenied: 'Access denied',
        onlyAdmin: 'Access for admin only',
        banned: 'You have been banned',
    },
    days: ['Monday', 'Thuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    form: {
        email: 'Email',
        password: 'Password',
        password2: 'Password confirmation',
        passwordNew: 'New password',
        passwordCurrent: 'Current password',
        terms: 'I declare that I accept <a href="/regulamin">terms and conditions</a> of {site}',
        marketing: 'I want to receive information about promotions by e-mail',
        errors: {
            fieldRequired: 'Field required',
            email: {
                wrong: 'Incorrect email address has been provided'
            },
            password: {
                min: 'The minimum password lenght is {min} characters',
                max: 'The maximum password lenght is {max} characters',
                notSame: 'Given passwords are not identical'
            },
            first_name: {
                min: 'The minimum lenght of name is {min} characters'
            },
            last_name: {
                min: 'The minimum lenght of surname is {min} characters'
            },
            terms: 'You must accept the terms and conditions'
        },
        save: 'Save'
    },
    addressSelector: {
        header: 'Wybierz adres',
        info: 'Enter the street and block number (without the apartment number)',
        placeholder: 'Street and block number',
        empty: 'No results, please enter a valid street. In case of problems, please contact us by phone',
        results: 'Found addresses (click to select)',
        apartment: 'Apartment number (optional)',
        floor: 'Floor number (optional)',
        staircase: 'Staircase number (optional)',
        button: 'Submit',
    },
    promocode: {
        kind: {
            discount: 'Price reduction (%)',
            addon: 'Free item',
            amount: 'Price reduction (amount)',
            delivery: 'Free delivery',
        },
        max_use_amount: {
            once: 'One use',
            once_per_user: 'One use per account',
            infinite: 'Unlimited',
        },
    },
    list: {
        showList: 'Show list',
        show: 'Show',
        id: 'ID',
        user: 'User',
        game: 'Game',
        count: 'Number',
        status: 'Status',
        amount: 'Amount',
        drawDate: 'Draw date',
        date: 'Date',
        price: 'Price',
        prize: 'Prize',
        type: 'Type',
        title: 'Title',
        orderId: 'Order number',
        address: 'Delivery address',
        filters: {
            header: 'Filtres',
            button: 'Filter',
            loading: 'Data loading',
            empty: 'No results',
            status: 'Status'
        },
        pagination: {
            prev: 'Previous',
            next: 'Next',
            page: 'Page',
            of: 'from {pageCount}'
        }
    },
    sayyes: 'Yes',
    sayno: 'No',
    soon: 'Soon',
}